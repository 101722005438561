import * as React from 'react';
import { ErrorPage } from '../../Layout/ErrorPage';
import styled from 'styled-components';
import { Grid, Typography } from '@stafes/gochikuru-now-ui';

const defaultErrorMessage =
  'サーバーで問題が発生している為、ページを表示できませんでした。' as const;

interface Props {
  message?: string;
}

const ErrorGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(8)}px 0;
`;

const InternalErrorPage: React.FC<Props> = ({
  message = defaultErrorMessage,
}) => (
  <ErrorPage>
    <ErrorGrid justifyContent="center">
      <Typography variant="h2">{message}</Typography>
    </ErrorGrid>
  </ErrorPage>
);

export { InternalErrorPage };
