import * as React from 'react';
import { NextPage } from 'next';
import NextError, { ErrorProps } from 'next/error';
import * as Sentry from '@sentry/node';
import { captureException } from '../logger/logger';
import { NotFoundPage } from '../components/Pages/NotFoundPage';
import { InternalErrorPage } from '../components/Pages/InternalErrorPage';

type ErrorPageProps = {
  err?: NextError; // FIXME: Workaround for https://github.com/zeit/next.js/issues/8592
  hasGetInitialPropsRun?: boolean;
} & ErrorProps;

const ErrorPage: NextPage<ErrorPageProps> = ({
  statusCode,
  err,
  hasGetInitialPropsRun,
}) => {
  if (!hasGetInitialPropsRun && err) {
    // FIXME: getInitialProps is not called in case of https://github.com/zeit/next.js/issues/8592.
    // As a workaround, we pass err via _app.js so it can be captured
    Sentry.captureException(err);
  }

  if (statusCode === 404) {
    return <NotFoundPage />;
  }

  return <InternalErrorPage />;
};

ErrorPage.getInitialProps = async (ctx) => {
  const { res, err, asPath } = ctx;

  const initialProps = await NextError.getInitialProps(ctx);

  // FIXME: Workaround for https://github.com/zeit/next.js/issues/8592, mark when getInitialProps has run
  const props = { ...initialProps, hasGetInitialPropsRun: true };

  if (!res && !err) {
    Sentry.captureException(
      new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
    );
    return props;
  }

  if (props.statusCode === 404) {
    return props;
  }

  if (err) {
    captureException(err);
  }

  return props;
};

export default ErrorPage;
