import * as React from 'react';
import { ErrorPage } from '../../Layout/ErrorPage';
import styled from 'styled-components';
import { Grid, Typography } from '@stafes/gochikuru-now-ui';

const ErrorGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(8)}px 0;
`;

const NotFoundPage: React.FC = () => (
  <ErrorPage>
    <ErrorGrid justifyContent="center">
      <Typography variant="h2">
        ご指定のページが見つかりませんでした。
      </Typography>
    </ErrorGrid>
  </ErrorPage>
);

export { NotFoundPage };
