import * as React from 'react';
import { WithFooterLayout } from '../WithFooterLayout';
import { CustomCard } from '../../CustomCard';
import { FormLogo } from '../../FormLogo';

const ErrorPage: React.FC = ({ children }) => (
  <WithFooterLayout>
    <CustomCard useBoxShadow={true}>
      <FormLogo />
      {children}
    </CustomCard>
  </WithFooterLayout>
);

export { ErrorPage };
